.portfolio__item-image img {
  width: 100%; /* Ensure the image takes up the entire container width */
  height: 260px; /* Fixed height for all images */
  object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 3px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
  margin-bottom: 0.5rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: right;
}

.icon-css,
.icon-boot {
  transform: scale(1.2);
}

.btn-demo:hover {
  background: var(--color-white) !important;
  color: var(--color-bg) !important;
  border-color: transparent !important;
}

.btn-live {
  width: 6rem !important;
  text-align: center;
}

.btn-github {
  width: 6rem !important;
}

.portfolio__item-tec {
  display: flex;
  gap: 1.4rem;
  margin-bottom: 2rem;
  justify-content: center; /* Align the grid to the right */
}

.tec-icon {
  color: var(--color-primary);
  font-size: 2rem;
}

.tec-icon:hover {
  color: rgb(95, 10, 20);
  cursor: default;
}

.expanded {
  margin-top: -20px;
  margin-bottom: 30px;
}

h4 {
  color: var(--color-primary);
}

h3 {
  text-align: center;
}

/* Medium devices */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* Small devices */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.experience__details-icon h3 {
  color: var(--color-white);
}

.experience__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center; /* Align the grid to the right */
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 3px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__frontend {
  padding: 4rem !important;
}

.experience__backend {
  padding: 4rem !important;
}

.experience__language {
  padding: 4rem !important;
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
}

.experience__details-icon {
  margin-top: 0px;
  color: var(--color-primary);
  font-size: 1.9rem;
}

.sub-heading {
  margin-top: 2px;
  color: var(--color-white) !important;
}

.icon-rest {
  font-size: 2.4rem;
}

/* Medium devices */
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 2fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    font-size: 1.1rem;
    padding: 1rem;
    margin-left: 1rem;
  }

  .experience__frontend,
  .experience__backend,
  .experience__language {
    padding: 3rem !important;
    font-size: 1.4rem;
  }

  .sub-heading {
    font-size: 1.3rem;
  }

  .experience__details-icon {
    font-size: 2.4rem;
  }
}

/* Small devices */
@media screen and (max-width: 600px) {
  .experience__container {
    width: 100%;
    margin: 2rem auto 4rem;
  }

  .experience__container > div {
    width: 80%;
  }

  .experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  .experience__frontend,
  .experience__backend,
  .experience__language {
    padding: 2rem !important;
    font-size: 1rem;
  }

  .experience__details {
    font-size: 12px;
  }

  .experience__content {
    font-size: 1.1rem;
    padding: 0rem;
    margin-left: 0rem;
  }

  .sub-heading {
    margin-top: 3px;
    font-size: 0.8rem;
  }

  .experience__details-icon {
    font-size: 1.9rem;
  }

  .icon-rest {
    font-size: 1.9rem;
  }
}

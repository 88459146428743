.container.contact__container {
  width: 37%;
  display: grid;
  gap: 12%;
}

/* FORM */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

h2 {
  margin-bottom: 0.5rem !important;
}

.message_txt {
  color: var(--color-white);
  text-align: center;
  font-weight: 400;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

textarea {
  height: 13.3rem;
}

.form-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Place this in your contact.css */
.confirmation-message {
  position: absolute;
  bottom: -40px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

/* Show confirmation message (visibility and opacity) */
.confirmation-message.show {
  opacity: 1;
  visibility: visible;
}

/* Medium devices */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    width: 50%;
    gap: 2rem;
  }
}

/* Small devices */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
